<template>
    <v-card
        min-width="100"
        min-height="60"
        elevation="0"
        variant="flat"
        class="rounded-4 ml-2 pa-8 py-6 no-hover no-cursor-pointer"
        @click="editable ? store.setEdit(props.item) : false"
    >
        <v-card-item class="pa-0">
            <span class="mx-0 font-weight-600 text-h6">
                {{ props.item.title }}
            </span>
            <template v-slot:append>
                <v-icon class="align-start mr-0" v-if="editable"> mdi-pencil</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="ml-0 ma-2 my-0 pa-0 text-caption">
            {{ props.item.company_name }}
        </v-card-text>
        <v-card-text class="ml-0 ma-2 my-0 pa-0 text-caption text-custom-grey-600">
            {{ displayDuration }}
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { UserJob } from '@/api/types/userJob';
    import { useUserJobStore } from '@/store/user-job/store';

    import { mapIsoDateToBrowserFormat } from '@/lib/dates';

    const store = useUserJobStore();
    const props = withDefaults(
        defineProps<{
            item: UserJob;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const displayDuration = computed(() => {
        const fromDate = props.item.from_date;
        const toDate = props.item.to_date;
        const formattedFrom = mapIsoDateToBrowserFormat(fromDate);
        const formattedTo = mapIsoDateToBrowserFormat(toDate);

        if (fromDate && toDate) {
            return `${formattedFrom} - ${formattedTo}`;
        } else if (fromDate) {
            return 'Current';
        } else {
            return '';
        }
    });
</script>
