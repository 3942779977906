<template>
    <v-carousel
        v-model="currentIndex"
        :show-arrows="true"
        :hide-delimiters="true"
        v-if="store.carouselItems.length"
        :height="427"
        :class="classes"
    >
        <v-carousel-item v-for="(item, i) in store.carouselItems" :key="item.id" cover>
            <PersonalValueCard
                :value="item"
                class="fill-height"
                :image="true"
                :removable="props.removable"
                @removed="$emit('removed:main-card', item)"
            />
        </v-carousel-item>
        <template v-slot:prev="{ props }">
            <PersonalValueCard
                v-if="prevCard"
                :value="prevCard"
                :width="iconCardWidth"
                class="rounded-xl fill-height"
                @click="props.onClick"
            />
            <span v-else></span>
        </template>
        <template v-slot:next="{ props }">
            <PersonalValueCard
                v-if="nextCard"
                :value="nextCard"
                :width="iconCardWidth"
                class="rounded-xl fill-height"
                @click="props.onClick"
            />
            <span v-else></span>
        </template>
    </v-carousel>
    <v-row class="pa-0 pt-6 pb-2 w-100 mt-n16" no-gutters align="center">
        <v-col :cols="isDesktop ? 3 : 2" class="pa-0 text-right">
            <v-btn
                v-if="prevCard !== null"
                class="rounded-4"
                variant="flat"
                color="custom-yellow"
                icon="mdi-arrow-left"
                @click="() => (prevCard !== null ? (currentIndex = currentIndex - 1) : null)"
            />
        </v-col>
        <v-col class="justify-center">
            <PersonalValueSubTypeButtons />
        </v-col>
        <v-col :cols="isDesktop ? 3 : 2" class="pa-0 text-left">
            <v-btn
                v-if="nextCard !== null"
                class="rounded-4"
                variant="flat"
                color="custom-yellow"
                :style="{ 'border-radius': '4px' }"
                icon="mdi-arrow-right"
                @click="() => (nextCard !== null ? (currentIndex = currentIndex + 1) : null)"
            ></v-btn>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import PersonalValueCard from '@/components/canvas/personal-values/upload/PersonalValueCard.vue';
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { storeToRefs } from 'pinia';
    import PersonalValueSubTypeButtons from '@/components/canvas/personal-values/upload/second-step/PersonalValueSubTypeButtons.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const store = usePersonalValuesStore();

    const props = defineProps<{
        removable?: boolean;
    }>();

    const { isDesktop } = useIsDesktop();

    // Bind store state to v-model
    // https://stackoverflow.com/questions/76370713/how-to-use-pinia-store-getters-on-v-model-of-an-input
    const { currentIndex } = storeToRefs(store);

    const { name } = useDisplay();
    const classes = computed(() => {
        const next = nextCard.value ? 'has-next ' : 'no-has-next';
        const prev = prevCard.value ? 'has-prev' : 'no-has-prev';
        return `personal-values-carousel ${next} ${prev} ${name.value}`;
    });

    const nextCard = computed(() => {
        if (currentIndex.value < store.carouselItems.length) {
            return store.carouselItems[currentIndex.value + 1];
        } else {
            return null;
        }
    });

    const prevCard = computed(() => {
        if (store.carouselItems.length > 0 && currentIndex.value > 0) {
            return store.carouselItems[currentIndex.value - 1];
        } else {
            return null;
        }
    });

    const iconCardWidth = computed(() => {
        switch (name.value) {
            case 'xs':
                return 180;
            case 'sm':
                return 200;
            case 'md':
                return 300;
            case 'xl':
                return 350;
            case 'xxl':
                return 400;
            default:
                return 300;
        }
    });
</script>
<style lang="scss" scoped>
    // Common styles for .v-window-item and .v-window__controls across all breakpoints
    :deep(.v-window-item) {
        align-self: center;
    }

    :deep(.v-window__controls) :first-child,
    :deep(.v-window__controls) :last-child {
        //border-radius: 32px;
        //height: 100%;
        // filter: blur(1px);
        //box-shadow: 0 1.619px 19.426px 0 rgba(0, 0, 0, 0.1);
        //background: #fff;
        //box-shadow: 0 1.619px 19.426px 0 rgba(0, 0, 0, 0.1);
        opacity: 0.6;
    }

    // Transition overrides
    .v-window-x-transition-enter-active,
    .v-window-x-transition-leave-active,
    .v-window-x-reverse-transition-enter-active,
    .v-window-x-reverse-transition-leave-active,
    .v-window-y-transition-enter-active,
    .v-window-y-transition-leave-active,
    .v-window-y-reverse-transition-enter-active,
    .v-window-y-reverse-transition-leave-active {
        transition: 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    // Breakpoints with specific values defined within each section
    .personal-values-carousel {
        &.xxl {
            --controls-height: 80%;
            --controls-top: 10%;
            --main-width: 500px;
            --gap: 550px;
            --left: 0%;
            --right: 0%;
            --no-has-prev: 0%;
            --no-has-next: 0%;
        }

        &.xl {
            --controls-height: 90%;
            --controls-top: 5%;
            --main-width: 440px;
            --gap: 480px;
            --left: 0%;
            --right: 0%;
            --no-has-prev: 0%;
            --no-has-next: 0%;
        }

        &.lg {
            --controls-height: 80%;
            --controls-top: 10%;
            --main-width: 300px;
            --gap: 340px;
            --left: 0%;
            --right: 0%;
            --no-has-prev: -30%;
            --no-has-next: -30%;
        }

        &.md {
            --controls-height: 70%;
            --controls-top: 20%;
            --main-width: 300px;
            --gap: 340px;
            --left: 0%;
            --right: 0%;
            --no-has-prev: -43%;
            --no-has-next: -44%;
        }

        &.sm {
            --controls-height: 70%;
            --controls-top: 20%;
            --main-width: 450px;
            --gap: 450px;
            --left: -5%;
            --right: -5%;
            --no-has-prev: -47%;
            --no-has-next: -47%;
        }

        &.xs {
            --controls-height: 70%;
            --controls-top: 20%;
            --main-width: 80%;
            --gap: 180;
            --left: -49%;
            --right: -49%;
            --no-has-prev: -55%;
            --no-has-next: -55%;
        }
    }

    // Use CSS variables in relevant styles
    .personal-values-carousel {
        :deep(.v-window-item) {
            width: var(--main-width);
        }

        :deep(.v-window__controls) {
            height: var(--controls-height);
            top: var(--controls-top);

            gap: var(--gap);

            :first-child,
            :last-child {
                @media (max-width: 1024px) {
                    // Adjust as necessary for specific breakpoints
                    width: 100% !important;
                }
            }
        }

        &.has-prev.has-next {
            :deep(.v-window__controls) :first-child {
                left: var(--left);
            }

            //
            :deep(.v-window__controls) :last-child {
                right: var(--right);
            }
        }

        &.no-has-prev.has-next {
            :deep(.v-window__controls) :last-child {
                right: var(--no-has-prev);
            }
        }

        &.has-prev.no-has-next {
            :deep(.v-window__controls) :first-child {
                left: var(--no-has-next);
            }
        }
    }
</style>
