import { create, fetch, update } from '@/services/base';
import {
    getPathwayChallenge,
    postPathwayChallengeStart,
    putPathwayChallengeUpdate,
    type StartChallengeCreateActionData,
    type UpdateChallengeActionData,
} from '@/services/plan/current/challenge/api';
import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import type { ApiResponse } from '@/services/apiClient';

export const fetchPathwayChallenge = function (
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<CanvasCurrentChallenge> {
    return fetch(
        'pathway-challenge',
        async () => await getPathwayChallenge(canvasId, challengeId, accessToken),
    );
};

export async function startPathwayChallenge(
    canvasId: number,
    challengeId: number,
    selectedActions: StartChallengeCreateActionData,
    accessToken: string,
): Promise<[CanvasCurrentChallenge, ApiResponse<CanvasCurrentChallenge>]> {
    return create(
        'start-pathway-challenge-state',
        async () =>
            await postPathwayChallengeStart(canvasId, challengeId, selectedActions, accessToken),
    );
}

export async function updatePathwayChallenge(
    canvasId: number,
    challengeId: number,
    selectedActions: UpdateChallengeActionData,
    accessToken: string,
): Promise<void> {
    return update(
        'update-pathway-challenge-state',
        async () =>
            await putPathwayChallengeUpdate(canvasId, challengeId, selectedActions, accessToken),
    );
}
