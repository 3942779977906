import type {
    CanvasLearnedExperience,
    EditableLearnedExperience,
} from '@/store/learned-experiences/types';
import {
    LearnedExperienceItemState,
    LearnedExperienceState,
} from '@/store/learned-experiences/types';
import type { UserJob } from '@/api/types/userJob';

export const byCreateDateComparator = (a: CanvasLearnedExperience, b: CanvasLearnedExperience) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
};

export const byFromDateComparatorDesc = (a: UserJob, b: UserJob) => {
    const dateA = a.from_date ? new Date(a.from_date).getTime() : -Infinity;
    const dateB = b.from_date ? new Date(b.from_date).getTime() : -Infinity;

    return dateB - dateA;
};

export function setEditableLearnedExperienceState(
    experience: EditableLearnedExperience,
    state: LearnedExperienceItemState,
): void {
    experience.prevStatus = experience.status;
    experience.status = state;
}

export function makeEditableLearnedExperience(
    experience: CanvasLearnedExperience,
): EditableLearnedExperience {
    return {
        id: experience.id,
        user_job_id: experience.user_job_id,
        title: experience.title,
        description: experience.description,
        prevStatus: null,
        status: LearnedExperienceItemState.Edit,
        state: LearnedExperienceState.UploadedByUser,
        enjoyment: experience.enjoyment,
        createAt: experience.created_at,
    };
}

export function makeNewEmptyEditableLearnedExperience(jobId: number): EditableLearnedExperience {
    return {
        user_job_id: jobId,
        title: '',
        prevStatus: null,
        status: LearnedExperienceItemState.New,
        state: LearnedExperienceState.UploadedByUser,
        enjoyment: 0,
        description: '',
    };
}
