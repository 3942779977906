<template>
    <teleport defer to="#guide-teleport-container-box">
        <div class="mx-0 mb-2 mr-4 pa-0 d-flex justify-end align-end text-left" v-if="show">
            <v-card
                class="cursor-pointer border-2-secondary mx-0 pa-5 app-guide-inline-card"
                :class="isDesktop ? 'w-100' : 'w-100 ml-5'"
            >
                <v-card-item
                    class="pa-0 mb-2"
                    v-if="closable"
                    :class="title || hasSlot('title') ? 'has-title' : 'no-title'"
                >
                    <div
                        v-if="title || hasSlot('title')"
                        class="text-wrap px-0 pt-4 mb-0 text-white text-heading-h3"
                    >
                        <span v-if="title">{{ title }}</span>
                        <slot v-else name="title" v-if="hasSlot('title')"></slot>
                    </div>
                    <template v-slot:append>
                        <v-icon @click="close" color="white" class="ma-2">mdi-close</v-icon>
                    </template>
                </v-card-item>
                <v-card-text class="pa-0">
                    <div class="text-paragraph-md text-white">
                        <div
                            class="pb-0 pl-0 pr-4 pt-4 text-paragraph-md text-word-phrase fill-height"
                        >
                            <span v-if="text">{{ text }}</span>
                            <slot v-else name="default" :close="close" />

                            <v-row
                                v-if="actionText"
                                no-gutters
                                class="mt-4 w-100 justify-end text-right"
                            >
                                <app-button
                                    @click="emit('submit')"
                                    :to="actionTo"
                                    class="text-paragraph-sm"
                                    color="primary"
                                    border="secondary"
                                    variant="flat"
                                >
                                    {{ actionText }}
                                </app-button>
                            </v-row>
                            <v-row v-else-if="hasSlot('actions')" no-gutters class="mt-4 w-100">
                                <slot class="" name="actions" :close="close" />
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </teleport>
</template>

<script setup lang="ts">
    import { ref, useSlots } from 'vue';
    import { useGuideStore } from '@/store/guide/store';
    import type { DeclaredRoute } from '@/router/routes';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    /**
     * This is a component that seems we are going to be using a lot in the app, so it is better to do an effor to have a flexible interface
     *
     *  - It allows title and text as props (usually when only text is passed)
     *  - It allows title and #default and #actions slots for a more deeper modification
     */
    const props = withDefaults(
        defineProps<{
            title?: string;
            text?: string;
            closable?: boolean;

            actionText?: string;
            actionTo?: DeclaredRoute;
        }>(),
        { closable: true },
    );

    const emit = defineEmits(['close', 'submit']);

    const { isDesktop } = useIsDesktop();
    const guideStore = useGuideStore();
    const show = ref(true);

    const close = () => {
        show.value = false;
        emit('close');
    };

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .app-guide-inline-card {
        border-radius: 12px;
        background: var(
            --Secondary-Purple-Gradient,
            linear-gradient(113deg, #1638a5 0%, #55199e 100%)
        );
        box-shadow: 0px 4px 8px 2px rgba(38, 38, 38, 0.08);
    }

    .avatar-inline-guide {
        margin-bottom: -35px !important;
        z-index: 999;
    }

    .v-card-item.no-title {
        position: absolute;
        right: 10px;
        top: 10px;
    }
</style>
