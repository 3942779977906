export enum StoryStatementType {
    WhoIAm = 'who_i_am',
    WhatIOffer = 'what_i_offer',
    WhatIAmWorkingOn = 'what_i_am_working_on',
    UVP = 'uvp',
}

export enum StoryStatementState {
    New = 'new',
    Progress = 'progress',
    Completed = 'completed',
    Edited = 'edited',
    Error = 'error',
}

export type ApiStoryStatement = {
    id: number;
    content: string | null;
    feedback: boolean | null; // null if no feedback, true if liked, false if disliked
    feedbackDescription: string | null;
    type: StoryStatementType;
    state: StoryStatementState;
    created_at: Date;
    updated_at: Date;
};

export interface ApiStory {
    id: number;
    canvas_id: number;
    statements: ApiStoryStatement[];
    latest_who_i_am: ApiStoryStatement | null
    latest_what_i_offer: ApiStoryStatement | null
    latest_what_i_am_working_on: ApiStoryStatement | null
    latest_uvp: ApiStoryStatement | null
    created_at: Date;
    updated_at: Date;
}
