<template>
    <v-card class="rounded-8 pa-4 bg-white app-card box-shadow-none">
        <v-card-text class="mx-0 px-0 pb-0 text-paragraph-md">
            <v-text-field
                variant="outlined"
                v-model="value!.title"
                label="Position"
                persistent-placeholder
                placeholder="What was the role?"
                class=""
            />

            <v-text-field
                variant="outlined"
                v-model="value!.companyName"
                label="Company"
                persistent-placeholder
                placeholder="What was the company name?"
                class=""
            />
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        variant="outlined"
                        v-model="value!.fromDate"
                        type="date"
                        label="Start date"
                        persistent-placeholder
                        class="mr-1"
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        variant="outlined"
                        v-model="value!.toDate"
                        type="date"
                        label="End date"
                        persistent-placeholder
                        class="ml-1"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="px-0 py-0">
            <SubmitButton :disabled="!store.isSubmitEnabled" text="Save" @click="onSubmit" />
            <app-button variant="flat" @click="onCancel" v-if="!isEdit"> Cancel</app-button>
            <v-spacer />

            <v-tooltip
                v-if="hasExperiences && showDelete"
                location="top"
                text="Please delete the experiences associated with this job first."
            >
                <template v-slot:activator="{ props }">
                    <div v-bind="props" style="display: inline-block">
                        <app-button variant="flat" style="opacity: 0.6" :disabled="hasExperiences"> Delete</app-button>
                    </div>
                </template>
            </v-tooltip>
            <app-button v-else @click="onDelete" v-if="showDelete"> Delete</app-button>
        </v-card-actions>
    </v-card>
</template>
<script setup lang="ts">
    import { computed, ref } from 'vue';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import { storeToRefs } from 'pinia';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';

    const store = useUserJobStore();
    const show = ref(true);

    const isDeleting = ref(false);

    const { value } = storeToRefs(store);

    const props = withDefaults(
        defineProps<{
            deletable?: boolean;
        }>(),
        { deletable: false },
    );

    const emit = defineEmits(['close', 'on-submit-success']);

    const close = () => {
        show.value = false;
        store.clear();

        emit('close');
    };

    const isEdit = computed(() => {
        return store.isEditing;
    });

    const showDelete = computed(() => {
        return props.deletable && store.isEditing;
    });

    const hasExperiences = computed(() => {
        return useLearnedExperiencesStore().jobsWithExperiences.some(
            (job) => job.id === store.value?.id,
        );
    });

    const onDelete = async () => {
        isDeleting.value = true;

        await store.deleteCurrent();

        if (!store.value) {
            isDeleting.value = false;
        }
    };

    const onCancel = async () => {
        store.clear();
    };

    const onSubmit = async () => {
        if (store.isEditing) {
            await store.saveJob();
        } else {
            const created = await store.createNewJob();
            if (created) {
                emit('on-submit-success', created);
            }
        }

        if (!store.isError) {
            close();
        }
    };
</script>
