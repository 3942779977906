<template>
    <app-page v-if="show">
        <app-page-header title="Account setup" :hide-tutorial="true" />
        <WelcomeLanding v-if="step === Steps.Landing" @next-click="next"/>
        <app-page-content v-else>
            <template v-slot:intro>
                <app-page-content-intro title="Let’s get you set-up!">
                    <div class="text-paragraph-md my-5">
                        We're going to ask you for some information on you now, that will help us to
                        personalise your journey, and shape future opportunities we present to you.
                        Please complete as accurately as you can.
                    </div>
                </app-page-content-intro>
            </template>
            <v-card v-if="isUpdating" variant="plain" height="400" class="w-100 text-white">
                <Loader
                    class="h-100 fill-height"
                    color="white"
                    css-class="text-white"
                    text="This could take a few seconds..."
                />
            </v-card>
            <WelcomeStepFirst v-else-if="step === Steps.SetName" />
            <WelcomeStepSecond v-else-if="step === Steps.Curriculum" />
            <WelcomeStepThird
                v-else-if="step === Steps.GetStarted"
                v-model:termsAndConditionModel="termsAndConditionModel"
                v-model:privacyModel="privacyModel"
            />
        </app-page-content>
        <app-page-footer>
            <template #actions>
                <SaveProgressButtons
                    close-text="Back"
                    :close-hide="step === Steps.Landing"
                    @on-back="previous"
                    submit-text="Continue"
                    :submit-disabled="isSubmitDisabled"
                    @on-submit="next"
                >
                </SaveProgressButtons>
                <!--                <CloseButton-->
                <!--                    v-if="step >= Steps.Landing"-->
                <!--                    :text="props.closeText"-->
                <!--                    :to="props.closeTo"-->
                <!--                    @click="previous"-->
                <!--                />-->
                <!--                <v-spacer/>-->

                <!--                <app-button-->
                <!--                    v-if="step === Steps.Landing"-->
                <!--                    @click="next"-->
                <!--                    color="primary"-->
                <!--                    variant="flat"-->
                <!--                    >Continue-->
                <!--                </app-button>-->
                <!--                <app-button-->
                <!--                    v-if="step === Steps.SetName"-->
                <!--                    @click="next"-->
                <!--                    color="primary"-->
                <!--                    :disabled="!userInputs.first_name"-->
                <!--                    variant="flat"-->
                <!--                    >Continue-->
                <!--                </app-button>-->
                <!--                <app-button-->
                <!--                    v-else-if="step === Steps.Curriculum && store.curriculumFile"-->
                <!--                    color="primary"-->
                <!--                    variant="flat"-->
                <!--                    @click="next"-->
                <!--                    :loading="store.isUpdating"-->
                <!--                >-->
                <!--                    Continue-->
                <!--                </app-button>-->
                <!--                <app-button-->
                <!--                    v-else-if="step === Steps.Curriculum"-->
                <!--                    class="font-weight-regular"-->
                <!--                    color=""-->
                <!--                    variant="outlined"-->
                <!--                    @click="next"-->
                <!--                    :loading="store.isUpdating"-->
                <!--                >-->
                <!--                    Skip for now-->
                <!--                </app-button>-->
                <!--                <app-button-->
                <!--                    v-if="step === Steps.GetStarted"-->
                <!--                    class="font-weight-light"-->
                <!--                    max-width="130"-->
                <!--                    color="primary"-->
                <!--                    variant="flat"-->
                <!--                    append-icon="mdi-arrow-right"-->
                <!--                    @click="onAcceptTermsAndConditionsClick"-->
                <!--                    :disabled="!termsAndConditionModel || !privacyModel"-->
                <!--                    :loading="store.isUpdating"-->
                <!--                >-->
                <!--                    Let's go!-->
                <!--                </app-button>-->
            </template>
        </app-page-footer>
    </app-page>
</template>

<script setup lang="ts">
    import { computed, onBeforeMount, onMounted, ref } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { useRouter } from 'vue-router';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { homeRoute } from '@/router/routes';
    import { useActivityStore } from '@/store/activity/store';
    import { storeToRefs } from 'pinia';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useDisplay } from 'vuetify';
    import WelcomeLanding from '@/components/welcome/WelcomeLanding.vue';
    import WelcomeStepSecond from '@/components/welcome/WelcomeStepSecond.vue';
    import WelcomeStepFirst from '@/components/welcome/WelcomeStepFirst.vue';
    import WelcomeStepThird from '@/components/welcome/WelcomeStepTermsAndConditions.vue';
    import Loader from '@/components/common/Loader.vue';
    import SaveProgressButtons from '@/components/footer-actions/SaveProgressButtons.vue';
    import { phoneNumberRules } from '@/components/profile/rules';

    const store = useUserProfileStore();
    const activityStore = useActivityStore();

    const router = useRouter();

    const { mdAndUp, smAndUp, smAndDown } = useDisplay();
    const { responsiveClass, isDesktop } = useIsDesktop();

    const termsAndConditionModel = ref(false);
    const privacyModel = ref(false);

    const isUpdating = ref(false);

    enum Steps {
        Landing = 0,
        SetName = 1,
        Curriculum = 2,
        GetStarted = 3,
    }

    const show = ref(false);
    const step = ref(Steps.Landing);
    const { userInputs } = storeToRefs(store);

    onBeforeMount(async () => {
        store.$reset();
    });

    onMounted(async () => {
        // TODO: HACK
        // Show the page after a short delay to allow the page to render
        setTimeout(() => {
            show.value = true;
        }, 100);
        store.init();

        // if (userStore.hasProfileBeenSet) {
        //     console.log(
        //         'Profile has already been set by user. Nothing to do on welcome page. Back to home',
        //     );
        //     await router.push(homeRoute);
        // }

        await activityStore.addEntryActivity(UserActivityArea.Welcome);
    });

    const previous = () => {
        step.value -= 1;
    };

    const next = async () => {
        if (step.value < Steps.GetStarted) {
            step.value += 1;
        } else {
            step.value += 1;
            await onAcceptTermsAndConditionsClick();
        }
    };

    // const watcher = watch(
    //     () => props.modelValue,
    //     (value) => {
    //         if (value) {
    //             step.value = 1;
    //         }
    //     },
    // );
    //
    // onBeforeUnmount(() => {
    //     watcher();
    // });

    const onAcceptTermsAndConditionsClick = async () => {
        try {
            isUpdating.value = true;

            await store.save(true);

            isUpdating.value = false;
            await router.push(homeRoute);
        } finally {
            isUpdating.value = false;
        }
    };

    const isSubmitDisabled = computed(() => {
        const inputs = userInputs.value;
        switch (step.value) {
            case Steps.SetName:
                return (
                    !inputs.first_name ||
                    !inputs.last_name ||
                    !inputs.email ||
                    !inputs.gender ||
                    !inputs.date_of_birth ||
                    phoneNumberRules(inputs.phone_number) !== true
                );
            case Steps.Curriculum:
                return (
                    !inputs.current_company ||
                    !inputs.job_title ||
                    inputs.current_company_confidence_rating === null
                ); // # || !store.curriculumFile;
            case Steps.GetStarted:
                return (
                    !inputs.location ||
                    !inputs.visa_type ||
                    !inputs.primary_language ||
                    !termsAndConditionModel.value ||
                    !privacyModel.value
                );
            default:
                return false;
        }
    });
</script>

<style scoped lang="scss">
    :deep(.text-field-transparent .v-input__slot) {
        background: transparent !important;
        opacity: 1;
    }

    // This will add an extra margin, so when errors are displayed in the fields the fields do not collapse
    :deep(.v-messages__message) {
        margin-top: 8px;
        padding-left: 0;
        padding-bottom: 16px;
    }
</style>
