<template>
    <v-container class="pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <CareerHistoryTimeline v-if="store.timeline.length" />
                <CareerHistoryEmptyCard v-else class="mt-4" :class="lgAndUp ? 'w-50' : 'w-100'" />
            </v-col>
        </v-row>

        <UserJobForm v-if="userJobStore.isCreating" :deletable="true" />
        <v-row no-gutters v-else class="pa-0">
            <app-button class="font-weight-medium my-5" @click="onAddNew">
                Add user job
            </app-button>
        </v-row>
    </v-container>

</template>

<script setup lang="ts">
    import { useUserJobStore } from '@/store/user-job/store';
    import UserJobForm from '@/components/canvas/user-jobs/UserJobForm.vue';
    import { useDisplay } from 'vuetify';
    import CareerHistoryTimeline from '@/components/profile/career-history/CareerHistoryTimeline.vue';
    import CareerHistoryEmptyCard from '@/components/profile/career-history/CareerHistoryEmptyCard.vue';

    const store = useUserJobStore();
    const userJobStore = useUserJobStore();

    const { lgAndUp } = useDisplay();

    const onAddNew = () => {
        store.addNew();
    };
</script>
