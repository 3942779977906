<template>
    <AppCard
        class="bg-white app-card box-shadow-none"
        :editable="false"
        :removable="true"
        @delete-click="onDelete"
        remove-icon="mdi-close"
    >
        <template v-slot:title>
            <v-row class="d-flex mr-5 align-center" no-gutters>
                <v-col :cols="isDesktop ? 6 : 12">
                    <v-text-field
                        variant="plain"
                        v-model="current!.description"
                        label="Skill"
                        :rules="[store.isAddingNew ? skillNewIsValidRule : skillEditIsValidRule]"
                        persistent-placeholder
                        placeholder="What's the name of this skill?"
                    />
                </v-col>
                <v-col v-if="isDesktop" cols="6" class="pa-0 justify-end text-right">
                    <div>
                        <AppSwitch @change="onChangeType" :items="switchOptions" />
                        <app-button variant="plain" v-if="isSubmitEnabled" @click="onSaveClick"
                            >Save
                        </app-button>
                        <v-divider
                            length="30"
                            thickness="2"
                            vertical
                            class="mx-0 mb-n2 mr-n4"
                            color="custom-neutral-grey-200"
                            opacity="100"
                        />
                    </div>
                </v-col>
                <v-col v-else cols="12" class="pa-0 text-left d-flex mt-2">
                    <AppSwitch @change="onChangeType" :items="switchOptions" />

                    <v-divider
                        v-if="false"
                        length="30"
                        thickness="2"
                        vertical
                        class="mx-4 mb-n2"
                        color="custom-neutral-grey-200"
                        opacity="100"
                    />
                    <app-button variant="plain" v-if="isSubmitEnabled" @click="onSaveClick"
                        >Save
                    </app-button>
                </v-col>
            </v-row>
        </template>
    </AppCard>
</template>

<script setup lang="ts">
    import { computed, onBeforeMount, ref } from 'vue';
    import { storeToRefs } from 'pinia';
    import { verify } from '@/store/verify';
    import { useSkillsStore } from '@/store/skills/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import AppSwitch from '@/components/buttons/AppSwitch.vue';
    import AppCard from '@/components/common/cards/AppCard.vue';
    import { type EditableSkill, SkillType } from '@/store/skills/types';
    import type { SwitchOption } from '@/components/canvas/skills/util';
    import { isCanvasSkill, isInferredCanvasSkill } from '@/api/types/canvas/skills';
    import { cloneDeep } from 'lodash';

    const store = useSkillsStore();
    const { current } = storeToRefs(store);

    const { isDesktop } = useIsDesktop();

    const originalValue = ref<EditableSkill | null>(null);

    const onChangeType = (option: SwitchOption) => {
        current.value!.type = option.value as SkillType;
    };

    onBeforeMount(() => {
        originalValue.value = cloneDeep(current.value!);
    });

    const switchOptions = computed(() => {
        return [
            {
                label: 'Learned',
                selected: current?.value?.type === SkillType.Learned,
                value: SkillType.Learned,
            },
            {
                label: 'Technical',
                selected: current?.value?.type === SkillType.Technical,
                value: SkillType.Technical,
            },
        ];
    });

    const onDelete = async () => {
        store.cancelEdit();
    };

    const isSubmitEnabled = computed(() => {
        const value = verify(current.value, 'current.value must exist while editing');

        const requiredData =
            value.type && value.description?.length !== undefined && value.description?.length > 0;

        if (requiredData) {
            if (store.isAddingNew) {
                return skillNewIsValidRule(value.description) === true;
            } else {
                const original = verify(
                    originalValue.value,
                    'originalValue must exist while editing',
                );
                if (
                    (original.type !== value.type || value.description !== original.description) &&
                    skillEditIsValidRule(value.description) === true
                ) {
                    return true;
                }
            }
        }

        return false;
    });

    const onSaveClick = async () => {
        if (store.isAddingNew) {
            await store.createSkill(current.value!);
        } else {
            await store.updateSkill(current.value!);
        }
    };

    const skillNewIsValidRule = (v: string) => {
        if (v.length === 0) {
            return 'This field is required';
        }

        let error = null;
        store.values.forEach((item) => {
            if (item.description === v) {
                error = 'You already added this skill';
                return;
            }
        });

        return error ?? true;
    };

    const skillEditIsValidRule = (v: string) => {
        if (v.length === 0) {
            return 'This field is required';
        }

        let error = null;
        store.values.forEach((item) => {
            if (item.description === v) {
                if (
                    isCanvasSkill(item) &&
                    isCanvasSkill(current.value) &&
                    item.id !== current.value.id
                ) {
                    error = 'You already added this skill';
                    return;
                }
                if (
                    isInferredCanvasSkill(item) &&
                    isInferredCanvasSkill(current.value) &&
                    item.id !== current.value.id
                ) {
                    error = 'You already added this skill';
                    return;
                }
            }
        });

        return error ?? true;
    };
</script>

<style lang="scss">
    .v-card.app-card {
        border: 1px dashed;
        border-color: rgb(var(--v-theme-custom-grey-700));
        border-image-slice: 4;
    }
</style>
