import { format } from 'date-fns';

const INPUT_DATE_BROWSER_FORMAT = 'yyyy-MM-dd';

/** Date with the following format:'1990-01-01T00:00:00Z'*/
export type ISO8601 = string;

export function mapBrowserDateStringToIso(date?: string | null): ISO8601 | null {
    if (date) {
        const d = _date(date);
        if (d) {
            return d.toISOString();
        }
    }
    return null;
}

export function mapIsoDateToBrowserFormat(date?: ISO8601): string | null {
    return toBrowserFormat(date ? _date(date) : null);
}

export function toBrowserFormat(date?: Date | null): string | null {
    return date ? format(date, INPUT_DATE_BROWSER_FORMAT) : null;
}

export function areSameDate(date1?: string, date2?: string): boolean {
    // If either date is undefined, return false
    if (date1 === undefined || date2 === undefined) {
        return false;
    }

    const d1 = _date(date1); // Handles both ISO 8601 and yyyy-MM-dd
    const d2 = _date(date2);

    // Extract only the date part (year, month, day) to compare
    return (
        !!d1 &&
        !!d2 &&
        d1.getUTCFullYear() === d2.getUTCFullYear() &&
        d1.getUTCMonth() === d2.getUTCMonth() &&
        d1.getUTCDate() === d2.getUTCDate()
    );
}

export function makeDate(date: string | null): Date | null {
    return date ? _date(date) : null
}

export function _date(date: string): Date | null {
    try {
        const d = new Date(date);

        // If the date is invalid, return null
        if (isNaN(d.getTime())) {
            console.warn('Invalid date:', date);
            return null;
        }

        return d;
    } catch (error) {
        console.warn('Error parsing date:', date, error);
        return null;
    }
}
