<template>
    <LearnedExperienceEditItem
        v-if="isEditingThisExperience"
        class="my-4"
    />
    <v-card
        v-else
        min-width="100"
        min-height="80"
        elevation="0"
        class="rounded-8 pa-4 py-6 border-2-grey-200"
        :class="editable ? 'cursor-pointer' : 'no-cursor-pointer'"
        @click="editable ? store.setEdit(props.item) : false"
    >
        <v-card-item class="pa-0">
            <span class="mx-0 font-weight-600 text-subtitle-1">
                {{ props.item.title }}
            </span>
            <template v-slot:append>
                <v-icon class="align-start mr-2" v-if="editable"> mdi-pencil</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="my-0 pa-0 text-caption text-custom-grey-600">
            {{ props.item.description }}
        </v-card-text>
    </v-card>

</template>

<script setup lang="ts">
    import { type CanvasLearnedExperience } from '@/store/learned-experiences/types';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceEditItem from '@/components/canvas/learned-experiences/LearnedExperienceEditItem.vue';
    import { computed } from 'vue';

    const props = withDefaults(
        defineProps<{
            item: CanvasLearnedExperience;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useLearnedExperiencesStore();

    const isEditingThisExperience = computed(() => {
        return store.isEditing && store.current?.id === props.item.id
    })
</script>
