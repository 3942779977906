import type { ApiStory, ApiStoryStatement } from '@/api/types/canvas/apiStory';
import type { Story, StorySection, StoryStatement } from '@/store/story/types';
import { StorySectionState } from '@/store/story/types';
import { StoryStatementType } from '@/api/types/canvas/apiStory';

export function mapApiStory(story: ApiStory): Story {
    return {
        id: story.id,
        canvasId: story.canvas_id,
        uvp: story.latest_uvp ?? null,
        whoIAm: story.latest_who_i_am ?? null,
        whatIOffer: story.latest_what_i_offer ?? null,
        whatIAmWorkingOn: story.latest_what_i_am_working_on ?? null,
        createdAt: story.created_at,
        updatedAt: story.updated_at,
    };
}