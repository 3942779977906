<template>
    <app-guide-inline
        v-if="store.hasInferred"
        text="I found some strengths in your CV and have added them here for you! Edit and change these as much as you need for it to be accurate to your current skills."
    />

    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.PersonalStrengths" />
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';

    const store = usePersonalStrengthsStore();
</script>
