<template>
    <v-card
        min-width="100"
        min-height="60"
        elevation="0"
        variant="flat"
        class="rounded-8 pa-8 py-6 border-2-grey-200"
        :class="editable ? 'cursor-pointer' : 'no-cursor-pointer'"
        @click="editable ? store.setEdit(props.item) : false"
    >
        <v-card-item class="pa-0">
            <span class="mx-0 font-weight-600 text-heading-h4">
                {{ props.item.title }}
            </span>
            <template v-slot:append>
                <v-icon class="align-start mr-0" v-if="editable"> mdi-pencil</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="ma-0 mt-2 pa-0 text-custom-grey-700 text-paragraph-sm">
            <span class="text-uppercase mr-4">{{ props.item.company_name }}</span>
            <span>{{ displayDuration }}</span>
        </v-card-text>
        <v-card-text class="ml-0 ma-2 my-0 pa-0 text-caption text-custom-grey-600"></v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { UserJob } from '@/api/types/userJob';
    import { useUserJobStore } from '@/store/user-job/store';

    import { makeDate } from '@/lib/dates';
    import { format } from 'date-fns';

    const store = useUserJobStore();
    const props = withDefaults(
        defineProps<{
            item: UserJob;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const displayDuration = computed(() => {
        const fromDate = makeDate(props.item.from_date ?? null);
        const toDate = makeDate(props.item.to_date ?? null);
        const formattedFrom = fromDate ? format(fromDate, 'MMM yyyy') : null;
        const formattedTo = toDate ? format(toDate, 'MMM yyyy') : null;

        if (formattedFrom && formattedTo) {
            return `${formattedFrom} - ${formattedTo}`;
        } else if (fromDate) {
            return 'Current';
        } else {
            return '';
        }
    });

    const cardColor = computed(() => {
        return 'white';
    });
</script>
