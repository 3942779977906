<template>
    <v-card
        class="story-uvp text-custom-black w-100"
        :class="isDesktop ? 'py-12 px-16' : 'px-8 py-8'"
        variant="flat"
        :min-height="hasStatement ? 200 : 160"
    >
        <v-row no-gutters class="pa-0">
            <v-col :cols="isDesktop ? 3 : 12">
                <div class="text-heading-h1">My superpower</div>
                <div class="text-paragraph-md mt-4">
                    Everyone is unique and brings something special to the workplace. Based on what
                    you've told us, we believe this is your superpower.
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col :cols="isDesktop ? 8 : 12" :class="isDesktop ? '' : 'mt-6'">
                <div v-if="hasStatement">
                    <span class="font-weight-700 text-custom-neutral-black uvp-statement" :class="responsiveClass">
                        {{ formattedStatement }}
                    </span>
                    <StorySectionStatementFeedback
                        v-if="store.uvp?.feedback === null || !store.uvp?.feedback"
                        style="max-width: 500px"
                        class="mt-4 bg-primary-yellow-light"
                        @click="onFeedback"
                    />
                    <!--                    <div class="font-weight-regular text-paragraph-sm text-custom-grey-900 mt-5">-->
                    <!--                        Last updated at: {{ lastUpdatedDate }}-->
                    <!--                    </div>-->
                </div>
                <StorySectionStatementInProgress
                    v-else-if="
                        store.uvp?.state === StoryStatementState.New ||
                        store.uvp?.state === StoryStatementState.Progress
                    "
                >
                </StorySectionStatementInProgress>
            </v-col>
        </v-row>
        <v-card-text v-if="hasStatement" class="mt-5 pa-0"></v-card-text>
        <v-card-text v-else class="mt-5 pa-0"></v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { format } from 'date-fns';
    import { useStoryStore } from '@/store/story/store';
    import StorySectionStatementFeedback from '@/components/story/section/statement/StorySectionStatementFeedback.vue';
    import { StorySectionId } from '@/store/story/types';
    import { verify } from '@/store/verify';
    import { StoryStatementState } from '@/api/types/canvas/apiStory';
    import StorySectionStatementInProgress from '@/components/story/section/statement/StorySectionStatementInProgress.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const store = useStoryStore();
    const { mdAndUp } = useDisplay();

    const { isDesktop, responsiveClass } = useIsDesktop();

    const onFeedback = async (value: boolean) => {
        const uvp = verify(store.uvp, 'UVP not found');
        await store.saveFeedback(StorySectionId.UVP, uvp, value);
    };

    const formattedStatement = computed(() => {
        const uvp = store.uvp;
        if (!uvp) {
            return '';
        }

        if (!uvp?.content) {
            return '';
        }
        console.log('FULL UVP WORKING::');
        const rawStatement = uvp.content;
        console.log(rawStatement);
        //The following formatting should ideally be done by the backend but keeping here for now to demonstrate working
        const adjustedStatement = rawStatement.replace('Step 3: ', '').replace('Step 4: ', '');
        const uvpStatement = adjustedStatement
            .substring(adjustedStatement.lastIndexOf('\n') + 1)
            .trim();
        console.log('UVP ONLY::');
        console.log(uvpStatement);
        return uvpStatement;
    });

    const lastUpdatedDate = computed(() => {
        if (!store.uvp) {
            return '';
        }

        return format(new Date(store.uvp?.created_at), 'dd/MM/yy hh:mm:ss a');
    });

    const hasStatement = computed(() => {
        return store.uvp?.content !== '' && store.uvp?.state === StoryStatementState.Completed;
    });
</script>

<style lang="scss">
    .story-uvp {
        border-radius: 30px !important;
        background: linear-gradient(94.29deg, #ffc32b 0%, #fb8625 100%) !important;
    }

    .uvp-statement {
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.4px;

        .mobile & {
            font-size: 26px;
        }
    }
</style>
