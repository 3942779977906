<template>
    <v-container :class="responsiveClass" class="welcome-card pa-0">
        <div class="w-100 pa-0 welcome-card-top-image" style="position: relative" fluid>
            <v-row justify="space-around" class="bg-custom-grey-100" no-gutters>
                <v-img :src="isDesktop ? welcomeBannerDesktop : welcomeBannerMobile" color="white" />
            </v-row>
        </div>
        <v-container class="welcome-card-content pa-0 pb-12" fluid>
            <div class="text-center" style="margin-bottom: 96px">
                <div class="text-heading-h1 align-center w-100 align-self-stretch mb-5">
                    We’re setting up a space just for you
                </div>
                <div class="text-paragraph-xl">
                    This will be your personal, unique workspace to make entirely your own. The more
                    we know about you, the more we can personalise your experience. You are going to
                    learn so much about yourself!
                </div>
            </div>

            <!-- WHY-->

            <div class="text-center" style="margin-bottom: 96px">
                <div class="text-caption-subtitle font-weight-bold" style="margin-bottom: 18px">
                    Why
                </div>
                <div class="text-heading-h1 w-100" style="margin-bottom: 48px">
                    We have designed Actvo to help you accelerate your career and reach your highest
                    potential
                </div>
                <v-row class="why-three-cards justify-space-between" no-gutters style="gap: 20px">
                    <v-col :cols="smAndDown ? 12 : ''" class="why-card px-5 py-8">
                        <div class="Search" style="font-size: 36px">
                            <app-fa-icon icon="search" />
                        </div>
                        <div class="why-card-content" :class="responsiveClass">
                            <div
                                class="w-100 justify-center text-center text-heading-h4 font-weight-700 text-custom-neutral-900"
                            >
                                Gain clarity & confidence
                            </div>
                            <div
                                class="w-100 text-center text-paragraph-md text-custom-neutral-900 px-0"
                            >
                                Because if you're clear on your worth, you will have more confidence to ask for what you need to progress.
                            </div>
                        </div>
                    </v-col>
                    <v-col :cols="smAndDown ? 12 : ''" class="why-card px-5 py-8">
                        <div class="Search" style="font-size: 36px">
                            <!-- TODO NACHO icon arrow-up-right seems not available  -->
                            <!-- TODO NACHO icon arrow-up-right seems not available  -->
                            <!-- TODO NACHO icon arrow-up-right seems not available  -->
                            <app-fa-icon icon="arrow-up-right-dots" />
                        </div>
                        <div class="why-card-content" :class="responsiveClass">
                            <div
                                class="w-100 justify-center text-center text-heading-h4 font-weight-700 text-custom-neutral-900"
                            >
                                Smash goals and gain new skills
                            </div>
                            <div
                                class="w-100 text-center text-paragraph-md text-custom-neutral-900 px-0"
                            >
                                We get that work can be hard. We want to arm you with the knowledge and skills you need to combat everyday challenges.
                            </div>
                        </div>
                    </v-col>
                    <v-col :cols="smAndDown ? 12 : ''" class="why-card px-5 py-8">
                        <div class="Search" style="font-size: 36px">
                            <app-fa-icon icon="compass" />
                        </div>
                        <div class="why-card-content" :class="responsiveClass">
                            <div
                                class="w-100 justify-center text-center text-heading-h4 font-weight-700 text-custom-neutral-900"
                            >
                                Move your vision for your future forward
                            </div>
                            <div
                                class="w-100 text-center text-paragraph-md text-custom-neutral-900 px-0"
                            >
                                There are so many opportunities at work now, but navigating to what’s right for you is hard. We'll help you figure it out.
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <!-- THE PROCESS -->

            <div class="text-center" style="margin-bottom: 96px">
                <div class="text-caption-subtitle font-weight-bold" style="margin-bottom: 18px">
                    The process
                </div>
                <div class="text-heading-h1 w-100" style="margin-bottom: 48px">
                    What we’re going to be doing
                </div>

                <v-row class="the-process-cards" style="gap: 20px" no-gutters>
                    <v-col :cols="smAndDown ? 12 : ''" class="the-process-card px-5 pb-8 pt-10">
                        <div
                            class="Frame7024"
                            style="
                                width: 48px;
                                height: 48px;
                                background: #ffc32c;
                                border-radius: 100px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                display: inline-flex;
                            "
                        >
                            <div
                                style="
                                    flex: 1 1 0;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 24px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 28.8px;
                                    word-wrap: break-word;
                                "
                            >
                                1
                            </div>
                        </div>
                        <div class="the-process-card-content" :class="responsiveClass">
                            <div
                                class="CraftingYourStory"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 20px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 24px;
                                    word-wrap: break-word;
                                "
                            >
                                Crafting your story
                            </div>
                            <div
                                class="DiscoveringHiddenTruthsAboutYouThatWeCanUseToNavigateYouForwardsThisIsTheFunBitTheMoreYouShareTheMoreWeCanHelpYouToDigDeep"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 16px;
                                    font-family: Inter;
                                    font-weight: 400;
                                    line-height: 25.6px;
                                    word-wrap: break-word;
                                "
                            >
                                Discovering hidden truths about you that we can use to propel you forwards. The more you share, the more we can help you to gain clarity on your unique value!
                            </div>
                        </div>
                    </v-col>
                    <v-col :cols="smAndDown ? 12 : ''" class="the-process-card px-5 pb-8 pt-10">
                        <div
                            class="Frame7024"
                            style="
                                width: 48px;
                                height: 48px;
                                background: #ffc32c;
                                border-radius: 100px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                display: inline-flex;
                            "
                        >
                            <div
                                style="
                                    flex: 1 1 0;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 24px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 28.8px;
                                    word-wrap: break-word;
                                "
                            >
                                2
                            </div>
                        </div>
                        <div class="the-process-card-content" :class="responsiveClass">
                            <div
                                class="SolvingEverydayChallenges"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 20px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 24px;
                                    word-wrap: break-word;
                                "
                            >
                                Solving everyday challenges
                            </div>
                            <div
                                class="WorkingTogetherOnWhatMightBeHoldingYouBackAndExploringWaysToOvercomeWithCreativeActivitiesThisIsWhereTheWorkStarts"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 16px;
                                    font-family: Inter;
                                    font-weight: 400;
                                    line-height: 25.6px;
                                    word-wrap: break-word;
                                "
                            >
                                Working together on what might be holding you back, and exploring ways to overcome challenges with creative activities. This is where the work starts!
                            </div>
                        </div>
                    </v-col>
                    <v-col :cols="smAndDown ? 12 : ''" class="the-process-card px-5 pb-8 pt-10">
                        <div
                            class="Frame7024"
                            style="
                                width: 48px;
                                height: 48px;
                                background: #ffc32c;
                                border-radius: 100px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                display: inline-flex;
                            "
                        >
                            <div
                                style="
                                    flex: 1 1 0;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 24px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 28.8px;
                                    word-wrap: break-word;
                                "
                            >
                                3
                            </div>
                        </div>
                        <div class="the-process-card-content" :class="responsiveClass">
                            <div
                                class="ForgingAFutureCareerPathway"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 20px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 24px;
                                    word-wrap: break-word;
                                "
                            >
                                Forging a future career pathway
                            </div>
                            <div
                                class="BackToTheFunStuffImaginingYourFutureThroughExploringDifferentPathwaysThatAlignToYourValuesAndYourGoalsThisIncludesActionsForHowToStart"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 16px;
                                    font-family: Inter;
                                    font-weight: 400;
                                    line-height: 25.6px;
                                    word-wrap: break-word;
                                "
                            >
                                Imagine knowing where your skills could take you? We want to help you to create a future pathway that aligns to your values and your goals. 
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <!-- MEET ALEX-->

            <!--            <div-->
            <!--                class="Frame7023"-->
            <!--                style="-->
            <!--                    align-self: stretch;-->
            <!--                    height: 580px;-->
            <!--                    flex-direction: column;-->
            <!--                    justify-content: flex-start;-->
            <!--                    align-items: center;-->
            <!--                    gap: 48px;-->
            <!--                    display: flex;-->
            <!--                "-->
            <!--            >-->
            <div class="text-center" style="margin-bottom: 96px">
                <div class="text-caption-subtitle font-weight-bold" style="margin-bottom: 18px">
                    Your own assistant
                </div>
                <div class="text-heading-h1 w-100" style="margin-bottom: 48px">Meet Alex</div>
                <div class="meet-alex-intro" :class="responsiveClass">
                    <app-guide-avatar size="165" class="pa-0" />
                    <!--                    <div class="meet-alex-intro-avatar-wrapper">-->
                    <!--                        <v-img :src="alexActive0" width="165" height="163" class="pa-0" />-->
                    <!--                    </div>-->
                    <!--                    <app-guide-avatar size="165" class="pa-0" />-->
                    <!--                    <v-avatar size="165" class="pa-0">-->
                    <!--                        <v-img :src="alexActive0" width="165" height="163" class="pa-0" />-->
                    <!--                    </v-avatar>-->
                    <div
                        class="text-paragraph-lg text-left text-custom-neutral-black"
                        style="flex: 1 1 0"
                    >
                    Hi, I’m Alex! I'm trained as a careers strategist and I'm always here for you to chat with and seek guidance. I will try to give you suggestions and prompts along the way but wherever you see me, let’s talk! Everything we discuss is private and my job is to fully support you 24/7.
                    </div>
                </div>
                <v-row
                    class="meet-alex-three-cards justify-space-between"
                    style="gap: 20px"
                    no-gutters
                >
                    <v-col :cols="smAndDown ? 12 : ''" class="meet-alex-card px-5 py-8">
                        <div
                            class="Message"
                            style="
                                color: #1638a5;
                                font-size: 36px;
                                font-family: Font Awesome 6 Pro;
                                font-weight: 400;
                                line-height: 57.6px;
                                word-wrap: break-word;
                            "
                        >
                            <!-- TODO NACHO icon with outline / regular seems not available  -->
                            <app-fa-icon icon="message" />
                        </div>
                        <div class="meet-alex-card-content" :class="responsiveClass">
                            <div
                                class="text-heading-h4 align-self-stretch text-center text-custom-neutral-black"
                            >
                                Have a conversation
                            </div>
                            <div class="text-center text-paragraph-md text-custom-neutral-900 px-0">
                                Alex is a highly tuned chat-bot designed to guide you through the app and answer any questions you have about your career.
                            </div>
                        </div>
                    </v-col>
                    <v-col :cols="smAndDown ? 12 : ''" class="meet-alex-card px-5 py-8">
                        <div
                            class="LifeRing"
                            style="
                                color: #1638a5;
                                font-size: 36px;
                                font-family: Font Awesome 6 Pro;
                                font-weight: 400;
                                line-height: 57.6px;
                                word-wrap: break-word;
                            "
                        >
                            <app-fa-icon icon="life-ring" />
                        </div>
                        <div class="meet-alex-card-content" :class="responsiveClass">
                            <div
                                class="text-heading-h4 align-self-stretch text-center text-custom-neutral-black"
                            >
                                Contextual support
                            </div>
                            <div
                                class="WhenYouSeeThePurpleCardsPoppingUpItsBecauseAlexHasSomeImportantInformationForYouThatsPersonal"
                                style="
                                    align-self: stretch;
                                    text-align: center;
                                    color: #262626;
                                    font-size: 16px;
                                    font-family: Inter;
                                    font-weight: 400;
                                    line-height: 25.6px;
                                    word-wrap: break-word;
                                "
                            >
                                When you see the 'purple cards' popping up, it’s because Alex has some important information for you that’s personal.
                            </div>
                        </div>
                    </v-col>
                    <v-col :cols="smAndDown ? 12 : ''" class="meet-alex-card px-5 py-8">
                        <div
                            class="Bullseye"
                            style="
                                color: #1638a5;
                                font-size: 36px;
                                font-family: Font Awesome 6 Pro;
                                font-weight: 400;
                                line-height: 57.6px;
                                word-wrap: break-word;
                            "
                        >
                            <app-fa-icon icon="bullseye" />
                        </div>
                        <div class="meet-alex-card-content" :class="responsiveClass">
                            <div
                                class="text-heading-h4 align-self-stretch text-center text-custom-neutral-black"
                            >
                                Here when you need
                            </div>
                            <div class="text-center text-paragraph-md text-custom-neutral-900 px-0">
                                Alex is always on, 24/7 everyday. All conversations are private and information stored securely. The more you call on them, the more insights they have on you.
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!--            </div>-->
            <div class="next-card cursor-pointer" :class="responsiveClass" @click="emit('next-click')">
                <div class="next-card--inner" :class="responsiveClass">
                    <div class="next-card-text-container text-left" :class="responsiveClass">
                        <div class="next-card-text-title">
                            <div
                                class="WhatIMWorkingOn"
                                style="
                                    align-self: stretch;
                                    color: #262626;
                                    font-size: 34px;
                                    font-family: Inter;
                                    font-weight: 700;
                                    line-height: 40.8px;
                                    word-wrap: break-word;
                                "
                            >
                                First up is building your profile
                            </div>
                        </div>
                        <div class="text-left next-card-text-content">
                            <p class="text-paragraph-md text-custom-neutral-900 px-0 mb-2">
                                We call it a 'Story' as it will change as your career grows. Take your time and it will reveal to you some invaluable insights.
                            </p>
                            <p class="text-paragraph-md text-custom-neutral-900 px-0 mb-2">
                                Let's go!
                            </p>
                        </div>
                    </div>
                    <!-- TODO ICON                    -->
                    <!-- TODO ICON                    -->
                    <!-- TODO ICON                    -->
                    <!-- TODO ICON                    -->
                    <!-- TODO ICON                    -->
                    <!-- TODO ICON                    -->
                    <div class="next-card-icon-container text-left" :class="responsiveClass">
                        <v-img
                            :src="arrowRightSVG"
                            :height="isDesktop ? 120 : 80"
                            :width="isDesktop ? 120 : 80"
                        />
                    </div>
                </div>
            </div>
        </v-container>
    </v-container>
</template>

<script setup lang="ts">
    import welcomeBannerDesktop from '@/assets/images/welcome/welcome-banner-desktop.svg';
    import welcomeBannerMobile from '@/assets/images/welcome/welcome-banner-mobile.svg';
    import arrowRightSVG from '@/assets/images/arrow-right.svg';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useDisplay } from 'vuetify';

    const emit = defineEmits(['next-click']);
    const { smAndDown } = useDisplay();
    const { responsiveClass, isDesktop } = useIsDesktop();
</script>

<style scoped lang="scss">
    .welcome-card.desktop {
        width: 1440px;
        padding-bottom: 64px;
        padding-left: 80px;
        padding-right: 80px;
        align-items: center;
        gap: 80px;

        .welcome-card-top-image {
            width: 1280px;
            //height: 441px;
        }

        .welcome-card-content {
            width: 996px;
            padding-top: 80px !important;
        }
    }

    .welcome-card.mobile {
        width: 100%;

        .welcome-card-top-image {
            width: 100%;
            height: 100%;
        }

        .welcome-card-content {
            width: 100%;
            padding-top: 80px !important;
        }
    }

    //.welcome-card {
    //    border: 1px solid rgb(var(--v-theme-secondary-blue));
    //}

    .why-card,
    .the-process-card {
        background: #fff8e5;
        border: 2px #ffc32b solid;
    }

    .meet-alex-card {
        background: #eee8f7;
        border: 2px #1638a5 solid;
    }

    .why-card,
    .the-process-card,
    .meet-alex-card {
        border-radius: 8px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: inline-flex;

        .why-card-content,
        .the-process-card-content,
        .meet-alex-card-content {
            align-self: stretch;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: flex;

            &.desktop {
                height: 180px;
            }

            &.mobile.sm {
                height: fit-content;
            }

            &.mobile.xs {
                height: fit-content;
            }
        }
    }

    .next-card.desktop {
        align-self: stretch;
        padding: 4px;
        background: #fff8e5;
        border-radius: 40px;
        border: 1px #ffc32b solid;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        display: flex;

        &.desktop {
            height: 250px;
        }

        .next-card--inner {
            align-self: stretch;
            border-radius: 36px;
            height: 100%;
            border: 1px #ffc32b dotted;
            justify-content: flex-start;
            align-items: center;
            gap: 80px;
            display: inline-flex;

            &.desktop {
                padding-left: 64px;
                padding-right: 64px;
            }

            .next-card-text-container {
                flex: 1 1 0;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 20px;
                display: inline-flex;

                .next-card-text-title {
                    align-self: stretch;
                    height: 41px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    display: flex;
                }
            }

            .next-card-icon-container {
                text-align: center;
                color: #ffc32b;
                font-size: 20.09px;
                font-family: Font Awesome 6 Pro;
                font-weight: 100;
                line-height: 120.09px;
                word-wrap: break-word;
            }
        }
    }

    .meet-alex-intro {
        &.desktop,
        &.mobile.md {
            width: 772px;
            gap: 32px;
            display: inline-flex;
            margin-bottom: 48px;

            .meet-alex-intro-avatar-wrapper {
                background: linear-gradient(180deg, #836eac 0%, #be96ba 100%);
                border-radius: 1500px;
                overflow: hidden;
                border: 3px #1638a5 solid;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

        &.mobile.sm,
        &.mobile.xs {
            gap: 32px;
            display: block;

            margin-bottom: 48px;

            .meet-alex-intro-avatar-wrapper {
                margin-bottom: 20px;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }

    .next-card.mobile {
        padding: 4px;
        background: #fff8e5;
        border-radius: 40px;
        border: 1px #ffc32b solid;
        gap: 10px;

        height: fit-content;

        .next-card--inner {
            border-radius: 36px;
            height: 100%;
            border: 1px #ffc32b dotted;

            padding: 32px 20px;

            .next-card-text-container {
                .next-card-text-title {
                    margin-bottom: 20px;
                }

                .next-card-text-content {
                    margin-bottom: 40px;
                }
            }

            .next-card-icon-container {
                text-align: left;
                color: #ffc32b;
                font-size: 20.09px;
                font-family: Font Awesome 6 Pro;
                font-weight: 100;
                line-height: 120.09px;
                word-wrap: break-word;
            }
        }
    }
</style>
