<template>
    <v-card
        color="custom-grey-100"
        class="custom-border-dashed-grey-600 empty-card"
        :width="mdAndUp ? 360 : '100%'"
        height="100"
        variant="flat"
    >
        <v-card-text class="mt-5 text-center"> No personal strengths added yet</v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';

    const { mdAndUp } = useDisplay();
</script>

<style scoped lang="scss">
    .empty-card {
        border-radius: 8px;
        border: 2px dashed var(--Grey-800, #474747);
    }
</style>
