export enum RoutesName {
    Home = 'home',
    AuthCallback = 'auth-callback',
    About = 'about',

    Profile = 'profile',
    CareerHistory = 'career-history',
    Welcome = 'welcome',
    GuideAdmin = 'admin/guide',

    Canvas = 'canvas',

    CanvasSkills = 'canvas-skills',
    CanvasPersonalStrength = 'canvas-personal-strength',

    CanvasPersonalityType = 'canvas-personality-type-upload',
    CanvasPersonalValues = 'canvas-personal-values',

    CanvasCurrentChallenges = 'canvas-current-challenges',
    CanvasFutureAspirations = 'canvas-future-aspirations',

    CareerHistoryExperiences = 'canvas-learned-experiences',
    CanvasInlineGuideStory = 'canvas-inline-guide-story',

    Story = 'story',

    Plan = 'plan',
    ChallengePath = 'challenge-path',
    SingleChallengeStart = 'challenge-start',
    SingleChallengeUpdateProgress = 'challenge-update-progress',
    SingleChallengeUpdateEdit = 'challenge-edit',
    SingleChallengeSequencing = 'challenge-sequencing',

    CurrentChallengePlan = 'current-challenge-plan',
    CareerPlan = 'career-plan',

    Alex = 'intro-alex',
    HowDoesItWork = 'how-does-it-work',
    Terms = 'terms-and-conditions',
    Privacy = 'privacy-policy',
}
