<template>
    <div class="w-100 mb-4" v-for="(timelineItem, index) in store.timeline || []">
        <CareerHistoryTimelineInferredUserJobCard
            v-if="isInferredUserJob(timelineItem)"
            class="w-100"
            :item="timelineItem"
            :index="index"
            :editable="editable"
        />
        <UserJobForm v-else-if="isEditingThisJob(timelineItem.id)" :deletable="true" />
        <CareerHistoryTimelineUserJobCard v-else :item="timelineItem" :editable="editable" />
    </div>
</template>

<script setup lang="ts">
    import CareerHistoryTimelineUserJobCard from '@/components/profile/career-history/CareerHistoryTimelineUserJobCard.vue';
    import { isInferredUserJob } from '@/api/types/userJob';
    import CareerHistoryTimelineInferredUserJobCard from '@/components/profile/career-history/CareerHistoryTimelineInferredUserJobCard.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import UserJobForm from '@/components/canvas/user-jobs/UserJobForm.vue';

    const props = withDefaults(
        defineProps<{
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useUserJobStore();

    const isEditingThisJob = (jobId: number) => {
        return store.isEditing && store?.value?.id === jobId;
    };
</script>

<style lang="scss" scoped></style>
