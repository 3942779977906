<template>
    <v-container
        fluid
        class="bg-primary-yellow-light pb-5 pt-5 mt-0 mx-0"
        :class="isDesktop ? 'before-main-content-desktop' : 'before-main-content-mobile'"
    >
        <div class="container-wrapper" no-gutters>
            <slot />
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { isDesktop } = useIsDesktop();
</script>

<style scoped lang="scss">
    .before-main-content-desktop {
        // This is a combination of css "hacks" to extend the background color of this component to the sides.
        // FIXME when the <MainContent> component does not work with columns anymore
        //margin-left: calc(var(--desktop-layout-horizontal-gap) * -1px);
        margin-right: calc(var(--desktop-layout-horizontal-gap) * -1px);
        padding-left: calc(var(--desktop-layout-horizontal-gap) * 1px);

        // This padding will dynamically adjust, but wont be perfect.
        // Needs to change once <MainContent> is addressed
        padding-right: calc(var(--desktop-layout-horizontal-gap) * 1px);

        .container-wrapper {
            text-align: left;
            width: 1440px;
            max-width: 1440px;
            margin: 0 auto; // This ensures horizontal centering
            padding: 0 80px; //
        }

    }

    .before-main-content-mobile {
        margin-top: -5px;
        padding-top: 32px;
    }
</style>
