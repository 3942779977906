<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Personal Strengths"
        action-text="Start"
        :steps="1"
    >
        <template v-slot:title>What makes you, you?</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    We are all unique and bring to the workforce innate personal strengths and traits. These are normally things we have been all our life - our personal characteristics that others find hard to replicate. Reflect back on what makes you intrinsically unique in this activity!
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
