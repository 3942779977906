<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Skills"
        action-text="Start"
        :steps="4"
    >
        <template #title="{ step }">
            <span v-if="step === 1">Welcome to the Skills Activity!</span>
            <span v-else-if="step === 2">Construct your skills in layers</span>
            <span v-else-if="step === 3">Identifying your skills</span>
            <span v-else-if="step === 4">Finishing the skills activity</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    This is a key one! Here we will help you unpack your individual skills you have gained into a 'stack' of skills which will contribute to your final superpower story. So make sure to include everything thats current.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    Learned skills (sometimes referred to as ‘Acquired Competencies’) are things you taught yourself on the job e.g. Influencing. This is normally the bulk of people’s skills so you will have a few. 
                </p>
                <p>
                    Technical skills are things you've been Trained or Certified in e.g. Designer. These are normally hard to fake! Try to just record things that are current and relevant in todays market. 
                </p>
            </div>
            <div v-else-if="step === 3">
                <p>
                    The best way to decide on your skills is to review your roles and projects you have undertaken in your career, and think critically about what skills you used to achieve your accomplishments.
                </p>
                <p>
                    Try to list your skills in as much detail as you can e.g. if you have learned Strategy, try to be specific about what type of Strategy skills you have eg Business Strategy or Marketing Strategy . This will help us guide you more accurately.
                </p>
            </div>
            <div v-else-if="step === 4">
                <p>
                    To complete this activity, you’ll need to add at least three learned skills, and one technical skill. Ideally, aim to add at least ten skills overall.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

const props = defineProps<{
    modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue']);
</script>
