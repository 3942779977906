<template>
    <app-error-boundary>
        <v-app class="app-container" :class="cssClasses">
            <AuthenticationProvider>
                <template v-if="userStore.isLoaded">
                    <AppHeaderDesktop v-if="isDesktop" />
                    <AppHeaderMobile v-else />
                    <v-main>
                        <!-- A container to prepend content without the main padding -->
                        <v-container id="before-main-container" class="pa-0" fluid/>
                        <v-container
                            class="pa-0 fill-height"
                            :class="isCurrent(storyRoute) ? 'story-container' : 'main-container'"
                        >
                            <router-view v-slot="{ Component }">
                                <suspense timeout="0">
                                    <template #fallback>
                                        <Loader
                                            class="fill-height"
                                            :title="loadingStore.title"
                                            :subtitle="loadingStore.subtitle"
                                        />
                                    </template>

                                    <template #default>
                                        <component :is="Component" />
                                    </template>
                                </suspense>
                            </router-view>
                        </v-container>
                    </v-main>

                    <v-container
                        id="guide-teleport-container"
                        :class="responsiveClass"
                        class="align-end justify-end text-right"
                    >
                        <div id="guide-teleport-container-box" />
                        <div id="guide-teleport-container-avatar" />
                    </v-container>

                    <!-- On certain desktop pages we are hiding the footer fot now -->
                    <AppFooter v-if="$route.meta.includeFooter" />
                </template>
            </AuthenticationProvider>
        </v-app>
    </app-error-boundary>
</template>

<script setup lang="ts">
    import { RouterView, useRoute } from 'vue-router';
    import AppHeaderDesktop from '@/components/header/AppHeaderDesktop.vue';
    import AppHeaderMobile from '@/components/header/AppHeaderMobile.vue';
    import { computed, onBeforeMount } from 'vue';
    import { useUsersStore } from '@/store/user/store';
    import AuthenticationProvider from '@/views/auth/AuthenticationProvider.vue';
    import { useAuth0 } from '@/auth/auth';
    import AppFooter from '@/components/footer/AppFooter.vue';
    import router from '@/router';
    import {
        type DeclaredRoute,
        privacyRoute,
        termsRoute,
        profileRoute,
        storyRoute,
        welcomeRoute,
    } from '@/router/routes';
    import AppErrorBoundary from '@/components/common/AppErrorBoundary.vue';
    import Loader from '@/components/common/Loader.vue';
    import { ApiAuthError } from '@/errors';
    import { useUserProfileStore } from '@/store/profile/store';
    import { useLoadingStore } from '@/store/loadingStore';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const userStore = useUsersStore();
    const profileStore = useUserProfileStore();
    const { user, getAccessTokenSilently } = useAuth0();

    const route = useRoute();

    const { responsiveClass, isDesktop } = useIsDesktop();

    const { logout } = useAuth0();

    const loadingStore = useLoadingStore();

    onBeforeMount(async () => {
        // Initialise user after the authentication from auth0 finished.
        try {
            await userStore.firstLoadUser(user, getAccessTokenSilently);
            profileStore.init();
        } catch (e) {
            if (e instanceof ApiAuthError) {
                // If there was an error authenticating the user, log them out.
                // That way, their session state is clean and they can try again.
                // If the session state is not clean, the user will just see
                // an "Unexpected application error" message.
                // NOTE! This error here is only evaluated on page (re)load.
                // This means that clicking around in the single-page application
                // will not trigger this error handling. However, this is good
                // enough for now. The main error we are handling is if the user
                // was disabled in the database, which is a rare case. Eventually,
                // the user will reload the page and get logged out, cleanly.
                // TODO: Handle this case also for clicks/actions within the single-
                // page application.
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                });
            } else {
                // Re-throw the error if it is not an AuthError.
                throw e;
            }
        }

        if (isCurrent(privacyRoute) || isCurrent(termsRoute)) {
            // TODO: This looks a bit hacky. Could be taken to some kind of router guard.
            console.log('User without profile set accessing privacy page.');
            return;
        } else if (!userStore.hasProfileBeenSet) {
            console.info(
                'User has been logged for first time. Navigating to welcome on boarding flow',
            );
            await router.push(welcomeRoute);
        } else if (!profileStore.isComplete) {
            console.warn('Profile is not complete. Redirecting to profile page');
            await router.push(profileRoute);
        } else if (userStore.hasProfileBeenSet) {
            console.log('Profile has already been set by user. Nothing to do on login');
            return;
        } else {
            console.info(
                'User has been logged for first time. Navigating to welcome on boarding flow',
            );
            await router.push(welcomeRoute);
        }
    });

    const isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };

    const cssClasses = computed(() => ({
        [responsiveClass.value]: true,
        ['story-page']: isCurrent(storyRoute),
        ['other-page']: !isCurrent(storyRoute),
    }));
</script>

<style scoped lang="scss">
    .app-container.desktop {
        --padding-top-main: 116px;
    }

    .app-container.mobile {
        --padding-top-main: 124px;
    }

    .app-container {
        &.desktop {
            header,
            main .main-container:not(.story-container),
            footer {
                padding-left: calc(var(--desktop-layout-horizontal-gap) * 1px) !important;
                padding-right: calc(var(--desktop-layout-horizontal-gap) * 1px) !important;
            }

            .main-container {
                max-width: 1440px;
            }

            .story-container {
                max-width: 1280px;
            }

            :deep(.v-toolbar__content) {
                width: 1280px;
                max-width: 1280px;
                //padding-left: 80px;
                //padding-right: 80px;
            }
            footer {
                :deep(.app-footer-container) {
                    padding-left: 80px;
                    padding-right: 80px;
                    max-width: 1440px;
                    width: 1440px;
                    margin: 0 auto; // This ensures horizontal centering
                }
            }
        }

        &.mobile {
            main .main-container {
                padding: 0 16px !important;
            }
        }
    }

    :deep(.v-main) {
        // This is the main container that holds the content of the page
        // Vuetify sets padding-top to 64px by default, which we want to override
        // to make the content start below the header
        padding-top: var(--padding-top-main);
    }

    .story-page #guide-teleport-container {
        bottom: 0px;
    }

    .other-page #guide-teleport-container {
        bottom: 95px; /* 95px for the footer height*/
    }

    #guide-teleport-container {
        padding: 0;
        position: sticky;
        margin-left: auto; /* Ensure it aligns to the right */
        text-align: right !important;
        margin-right: 0px !important;

        // one less than the z-index of the guide
        z-index: 2399;

        &.desktop {
            width: 500px;
        }
        &.mobile {
            width: 100%;
        }
    }
</style>
