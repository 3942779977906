import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import { type EditablePlanAction, PlanActionItemState } from '@/store/plan/common/types';
import type { CanvasFutureAspiration } from '@/api/types/canvas/futureAspiration';
import type { CurrentChallengeAction } from '@/api/types/plan/currentChallengeAction';
import type { FutureAspirationAction } from '@/api/types/plan/futureAspirationAction';

/**
 * Returns a due date which is in 4 weeks time.
 */
export function makeDueDate(): Date {
    const now = new Date();
    const fourWeeks = 4;
    return new Date(now.setDate(now.getDate() + fourWeeks * 7));
}

export const byCreateDateComparator = (
    a: CanvasFutureAspiration | CanvasCurrentChallenge,
    b: CanvasFutureAspiration | CanvasCurrentChallenge,
) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
};

export const byDueDateComparator = (a: EditablePlanAction, b: EditablePlanAction) => {
    return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
};

export function setEditableActionState(
    action: EditablePlanAction,
    state: PlanActionItemState,
): void {
    action.prevState = action.state;
    action.state = state;
}

export function makeNewEmptyEditableAction(): EditablePlanAction {
    const dueDate = makeDueDate();
    return {
        state: PlanActionItemState.Edit,
        prevState: null,
        title: '',
        due_date: dueDate,
    };
}

export function makeEditableAction(action: CurrentChallengeAction | FutureAspirationAction) {
    return {
        id: action.id,
        prevState: null,
        state: PlanActionItemState.Saved,
        title: action.title,
        description: action.description,
        action_type: action.action_type,
        due_date: new Date(action.due_date),
    };
}
