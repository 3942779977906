<template>
    <v-card
        min-width="100"
        min-height="60"
        elevation="0"
        class="rounded-4 pa-8 py-6 border-2-grey-200"
    >
        <v-card-item class="pa-0">
            <span class="mx-0 font-weight-600 text-h6">
                {{ props.item.title }}
            </span>
        </v-card-item>
        <v-card-text class="ml-0 ma-2 my-0 pa-0 text-caption">
            {{ props.item.company_name }}
        </v-card-text>
        <v-card-text class="ml-0 ma-2 my-0 pa-0 text-caption text-custom-grey-600">
            {{ displayDuration }}
        </v-card-text>
        <v-card-actions
            class="pa-0 pt-1 mt-4 w-100 d-block"
            :class="isDesktop ? 'desktop-actions' : ''"
        >
            <v-row no-gutters align="end">
                <div class="text-subtitle-2 mb-2">
                    We inferred this job from your cv. Did we get this right?
                </div>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <app-button
                        variant="flat"
                        color="custom-grey-100"
                        class="font-weight-light mr-4"
                        @click="onDiscard"
                        :disabled="isSaving"
                        :loading="isDiscarding"
                    >
                        Discard
                    </app-button>
                    <SubmitButton
                        text="Confirm this job"
                        @click="onSubmit"
                        :disabled="isSaving"
                        :loading="isAccepting"
                    />
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import type { InferredUserJob } from '@/api/types/userJob';
    import { useUserJobStore } from '@/store/user-job/store';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { useDisplay } from 'vuetify';

    import { mapIsoDateToBrowserFormat } from '@/lib/dates';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const store = useUserJobStore();
    const props = withDefaults(
        defineProps<{
            item: InferredUserJob;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const { isDesktop } = useIsDesktop();

    const isDiscarding = ref(false);
    const isAccepting = ref(false);

    const onDiscard = async () => {
        try {
            isDiscarding.value = true;
            await store.denyInferredUserJob(props.item);
        } finally {
            isDiscarding.value = false;
        }
    };
    const onSubmit = async () => {
        try {
            isAccepting.value = true;
            await store.acceptInferredUserJob(props.item);
        } finally {
            isAccepting.value = false;
        }
    };

    const isSaving = computed(() => {
        return isDiscarding.value || isAccepting.value;
    });

    const displayDuration = computed(() => {
        const fromDate = props.item.from_date;
        const toDate = props.item.to_date;
        const formattedFrom = mapIsoDateToBrowserFormat(fromDate);
        const formattedTo = mapIsoDateToBrowserFormat(toDate);

        if (fromDate && toDate) {
            return `${formattedFrom} - ${formattedTo}`;
        } else if (fromDate) {
            return 'Current';
        } else {
            return '';
        }
    });

    const cardColor = computed(() => {
        return 'white';
    });
</script>

<style lang="scss" scoped>
    .desktop-actions {
        position: relative;
        float: right;
        width: fit-content !important;
    }
</style>
