<template>
    <LearnedExperienceGuideModalExplanation v-model="showTutorial" />
    <app-page>
        <app-page-header title="Memorable Experiences" @show-tutorial="showTutorial = true" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Career history">
                    Start by recording all your significant jobs you have had in your career, with
                    as much detail as you can.
                </app-page-content-intro>
            </template>

            <CareerHistoryContent />
        </app-page-content>

        <UploadCurriculumGuideInline v-if="!profileStore.hasCurriculum" />

        <app-page-footer>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions
                    title="Continue"
                    :submit-to="learnedExperienceRoute"
                />
            </template>
        </app-page-footer>
    </app-page>

    <CareerHistoryGuide />
</template>

<script setup lang="ts">
    import UploadCurriculumGuideInline from '@/components/canvas/learned-experiences/UploadCurriculumGuideInline.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import CareerHistoryContent from '@/components/profile/career-history/CareerHistoryContent.vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { learnedExperienceRoute } from '@/router/routes';
    import CareerHistoryGuide from '@/components/profile/career-history/CareerHistoryGuide.vue';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import { useCanvasStore } from '@/store/canvas/store';
    import LearnedExperienceGuideModalExplanation from '@/components/canvas/learned-experiences/guide-modals/LearnedExperienceGuideModalExplanation.vue';
    import { ref } from 'vue';

    const showTutorial = ref(false);

    const canvasStore = useCanvasStore();
    const userJobStore = useUserJobStore();
    const profileStore = useUserProfileStore();
    const experiencesStore = useLearnedExperiencesStore();

    await canvasStore.load();
    await userJobStore.load();
    await experiencesStore.load();
</script>
