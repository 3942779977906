<template>
    <app-page>
        <app-page-header title="Values" @show-tutorial="showTutorial = true" />

        <PersonalValuesGuideModalExplanation v-model="showTutorial" />
        <PersonalValuesGuideModalKeepAdding />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Select your core or growth values">
                    <div class="text-paragraph-md my-5">
                        This is a tricky activity! Take your time and choose carefully, as all the values presented are good - but you can only hold 6 Core values and 6 Growth values. It's a process of elimination - focus on what matters most, to you now, in your working life.
                    </div>

                    <div class="text-heading-md font-weight-600">Core values</div>
                    These are the values that guide your life decisions, and generally don't change. They are values you have probably held since you were a child, and intend to keep honouring. 

                    <div class="text-heading-md font-weight-600 mt-5">Growth values</div>
                    These are those values you'd like to grow into and adopt more in your future. When you choose to change your values it allows you to grow into new ways of living and working.
                    
                </app-page-content-intro>
            </template>

            <!-- With the step being only 1, the progress bar now is confusing -->
            <PersonalValueProgressBar
                v-if="false && store.isLoaded"
                :completed="store.currentIndex + 1"
                :total="progressUpperLimit"
            />

            <v-container class="px-0">
                <v-row no-gutters justify="space-around">
                    <v-col>
                        <PersonalValuesCarousel
                            @removed:main-card="onMainCardRemoved"
                            :removable="false"
                        />
                    </v-col>
                </v-row>
            </v-container>

            <v-divider class="my-12" />

            <PersonalValueSubTypesColumns />
        </app-page-content>
        <app-page-footer>
            <template v-slot:errors>
                <SubmitNotification
                    :store="store"
                    :activity-area="UserActivityArea.PersonalValues"
                />
            </template>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions :dummy="true" />
            </template>
        </app-page-footer>

        <PersonalValuesGuide v-if="store.isLoaded" />
        <PageActivity :area="UserActivityArea.PersonalValues" />
    </app-page>
</template>

<script setup lang="ts">
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import PersonalValueSubTypesColumns from '@/components/canvas/personal-values/upload/second-step/PersonalValueSubTypesColumns.vue';
    import PersonalValueProgressBar from '@/components/canvas/personal-values/upload/PersonalValueProgressBar.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import PersonalValuesGuide from '@/components/canvas/personal-values/PersonalValuesGuide.vue';
    import PersonalValuesCarousel from '@/components/canvas/personal-values/upload/PersonalValuesCarousel.vue';
    import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PersonalValuesGuideModalExplanation from '@/components/canvas/personal-values/guide-modals/PersonalValuesGuideModalExplanation.vue';
    import PersonalValuesGuideModalKeepAdding from '@/components/canvas/personal-values/guide-modals/PersonalValuesGuideModalKeepAdding.vue';
    import type { PersonalValue } from '@/api/types/personalValue';
    import { watchDebounced } from '@vueuse/core';

    const showTutorial = ref(false);
    const store = usePersonalValuesStore();

    onMounted(() => {
        if (store.totalImportantTaggedValues === 0) {
            showTutorial.value = true;
        }
    });

    const progressUpperLimit = computed(() => {
        return store.total - store.totalImportantTaggedValues;
    });

    const watcher = watchDebounced(
        store.alreadyTagged,
        async () => {
            await store.saveProgress();
        },
        { debounce: 300, maxWait: 3000 },
    );

    onBeforeUnmount(() => {
        watcher();
    });

    const onMainCardRemoved = (item: PersonalValue) => {
        store.removeCardFromSecondStep();
    };
</script>
