<template>
    <v-app-bar elevation="0" color="custom-yellow" class="pt-8 pb-5 app-desktop-header align-center">
        <template v-slot:prepend>
            <AppHeaderLogo />
        </template>
        <template v-slot:append>
            <AppHeaderNavigation v-if="userStore.hasProfileBeenSet" />
            <AppHeaderUserAvatar
                v-if="userStore.hasProfileBeenSet"
                :user="userStore.user"
                :clickable="true"
                class="ml-16"
            />
            <DebugDisplay />
        </template>
    </v-app-bar>
</template>

<script setup lang="ts">
    import DebugDisplay from '@/components/header/DebugDisplay.vue';
    import { useUsersStore } from '@/store/user/store';
    import AppHeaderUserAvatar from '@/components/header/AppHeaderUserAvatar.vue';
    import AppHeaderLogo from '@/components/header/AppHeaderLogo.vue';
    import AppHeaderNavigation from '@/components/header/AppHeaderNavigation.vue';

    const userStore = useUsersStore();
</script>

<style scoped lang="scss">
    .app-desktop-header {
        padding-top: 32px;
        padding-bottom: 20px;
    }
</style>
