import { defineStore } from 'pinia';
import {
    DataState,
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { makeAccessTokenState, type WithAccessToken } from '@/store/common/accessTokenState';
import type { InferredUserJob, UserJob } from '@/api/types/userJob';
import type { CanvasLearnedExperience, EditableUserJob } from '@/store/learned-experiences/types';
import { useUsersStore } from '@/store/user/store';
import {
    createUserJob,
    removeUserJob,
    updateInferredUserJob,
    updateUserJob,
} from '@/services/profile/service';
import { ProfileErrorType } from '@/store/profile/types';
import { byFromDateComparatorDesc } from '@/store/learned-experiences/utils';
import { verify } from '@/store/verify';
import { cloneDeep } from 'lodash';
import { InferredRecordState } from '@/api/types/canvas/skills';
import type { User } from '@/api/types/user';

import { mapIsoDateToBrowserFormat } from '@/lib/dates';

type UserJobsStoreState = {
    value: EditableUserJob | null;
    inferredJobs: InferredUserJob[];
    jobs: UserJob[];
    state: DataState;
    error: any | null;
} & WithAccessToken;

export type ExperienceTimelineJob = (UserJob | InferredUserJob) & {
    experiences?: CanvasLearnedExperience[];
};

export const useUserJobStore = defineStore({
    id: 'user-jobs-store',
    state: (): UserJobsStoreState => ({
        value: null,
        jobs: [],
        inferredJobs: [],
        ...makeDataState(),
        ...makeAccessTokenState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        timeline(state): ExperienceTimelineJob[] {
            const withInferredState = this.nonActionedInferredUserJobs;
            return [...state.jobs, ...withInferredState].sort(byFromDateComparatorDesc);
        },
        isCreating(): boolean {
            return !!this.value && !this.value.id;
        },
        isEditing(): boolean {
            return !!this.value && !!this.value?.id;
        },
        isSubmitEnabled(state): boolean {
            const job = state.value;
            return (!!job &&
                job.title?.length !== undefined &&
                job.title?.length > 0 &&
                job.companyName?.length !== undefined &&
                job.companyName?.length > 0) as boolean;
        },
        hasUserJobs(): boolean {
            return this.jobs.length > 0;
        },
        hasNonActionedInferredUserJobs(): boolean {
            return this.nonActionedInferredUserJobs.length > 0;
        },
        nonActionedInferredUserJobs(state): InferredUserJob[] {
            return state.inferredJobs.filter((job) => job.state === InferredRecordState.Inferred);
        },
    },
    actions: {
        addNew(): void {
            this.value = {
                title: '',
                companyName: '',
                fromDate: mapIsoDateToBrowserFormat() ?? undefined,
                toDate: mapIsoDateToBrowserFormat() ?? undefined,
            };
        },
        setEdit(entry: UserJob): void {
            this.value = {
                id: entry.id,
                title: entry.title,
                companyName: entry.company_name,
                fromDate: mapIsoDateToBrowserFormat(entry.from_date) ?? undefined,
                toDate: mapIsoDateToBrowserFormat(entry.to_date) ?? undefined,
            };
        },
        clear(): void {
            this.value = null;
        },
        async load() {
            console.info('Loading user jobs ...');

            setInLoading(this);

            try {
                await this._reloadState();

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        async createNewJob(): Promise<UserJob | null> {
            verify(this.isCreating, 'Cannot create a new job when editing an existing one');
            const job = verify(this.value, 'Cannot create a new job without a value');

            const { user, accessToken } = await useUsersStore().makeContext();

            try {
                setInUpdating(this);

                const userJob = await createUserJob(user.id, job, accessToken);

                await this._reloadState();

                setInIdle(this);

                return userJob;
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, ProfileErrorType.UserJob);
                return null;
            }
        },
        async saveJob(): Promise<void> {
            verify(this.isEditing, 'Cannot save a job when creating a new one');
            const job = verify(this.value, 'Cannot save a job without a value');
            const jobId = verify(job.id, 'Cannot save a job without an ID');

            const { user, accessToken } = await useUsersStore().makeContext();

            try {
                setInUpdating(this);

                await updateUserJob(user.id, jobId, job, accessToken);

                await this._reloadState();

                this.clear();
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, ProfileErrorType.UserJob);
            }
        },
        async deleteCurrent(): Promise<void> {
            const { accessToken } = await useUsersStore().makeContext();

            try {
                const { user } = await useUsersStore().makeContext();
                const userJob = verify(this.value, 'No user job');

                await removeUserJob(user.id, userJob.id!, accessToken);

                await this._reloadState();
                this.clear();
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
            }
        },
        async acceptInferredUserJob(inferredUserJob: InferredUserJob): Promise<void> {
            await this._updateInferredUserJobState(inferredUserJob, InferredRecordState.Accepted);
        },
        async denyInferredUserJob(inferredUserJobs: InferredUserJob): Promise<void> {
            await this._updateInferredUserJobState(inferredUserJobs, InferredRecordState.Denied);
        },
        _reloadState: async function () {
            // Manually trigger a reload of the user resource, to keep FKs updates
            const user = await useUsersStore().synchroniseCurrentUser();

            this._setState(verify(user, 'No user to reload'));
        },
        async _updateInferredUserJobState(
            inferredUserJob: InferredUserJob,
            entryState: InferredRecordState,
        ): Promise<void> {
            verify(
                inferredUserJob.state === InferredRecordState.Inferred,
                'UserJob is not inferred',
            );

            const { accessToken, user } = await useUsersStore().makeContext();

            const form = verify(inferredUserJob, 'No current job');
            const formId = verify(form.id, 'No job id');

            const [_inferredUserJob, _newUserJob] = await updateInferredUserJob(
                user.id,
                formId,
                { ...form, state: entryState },
                accessToken,
            );

            await this._reloadState();
        },
        _setState: function (user: User) {
            this.jobs = cloneDeep(user.user_jobs);
            this.inferredJobs = cloneDeep(user.inferred_user_jobs);
        },
    },
});
