<template>
    <app-guide-inline
        v-if="store.hasInferredSkills"
        text="I have found some skills in your CV and added them here for you! Edit and change these as much as you need for it to be accurate to your current skills."
    />
    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.Skills" />
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { useSkillsStore } from '@/store/skills/store';

    const store = useSkillsStore();
</script>
